import { JsHelpers } from 'helpers/helpers';

import { Sidebar } from 'modules/sidebar';
import { Nav } from 'modules/nav';
import { Accordion } from 'modules/accordion';
// import { Scrollbar } from 'modules/scrollbar';
import { Modal } from 'modules/modal';
import { Dropdown } from 'modules/dropdown';
import { Toggle } from 'modules/toggle';
import { ChartBox } from 'modules/chartbox';
import { Tabs, PrepareTabs } from 'modules/tabs';
import { CheckUncheck } from 'modules/check-uncheck';
import { PPTX } from 'modules/pptx';
import * as request from 'superagent';

let sidebar = new Sidebar();
let nav = new Nav();

let accordions = [];
let accordionsElements = document.querySelectorAll('.js-accordion');
if(accordionsElements != null) {
    accordionsElements.forEach(accordion => {
        accordions.push(new Accordion(accordion));
    });
}

let dropdowns = [];
let dropdownElements = document.querySelectorAll('.js-dropdown');
if(dropdownElements != null) {
    dropdownElements.forEach(dropdown => {
        dropdowns.push(new Dropdown(dropdown));
    });
}

let toggles = [];
let toggleElements = document.querySelectorAll('.js-toggle');
if(toggleElements != null) {
    toggleElements.forEach(toggle => {
        toggles.push(new Toggle(toggle));
    });
}

// let scrollbars = [];
// let scrollbarElements = document.querySelectorAll('.js-scroll');
// if(scrollbarElements != null) {
//     scrollbarElements.forEach(scrollbarElement => {
//         scrollbars.push(new Scrollbar(scrollbarElement));
//     });
// }

// console.log('SCROLLBARS', scrollbars);

window.agregg = {
    debugMode: false,
    chartboxes: [],
    widgets: [],
    tables: [],
    maps: [],
    graphs: []
};

document.body.onkeydown = function(e){
    if(e.key == "q" && e.ctrlKey) {
        console.log('ctrl+q was pressed, activate debug mode');
        var debugs = document.querySelectorAll('.debug_info');
        debugs.forEach((debug_info) => {
            if (debug_info.style.display === "none") {
                debug_info.style.display = "block";
            } else {
                debug_info.style.display = "none";
            }
        });
    }

    if(e.key == "b" && e.ctrlKey) {
        console.log('ctrl+b downloading pptx...');
        let all_charts = document.querySelectorAll('.c-chartbox, .c-graph-pptx');
        let c = new PPTX();
        c.start(all_charts);
    }

};

let download_action = document.getElementById("download__actions");
if (download_action){
    download_action.addEventListener('click', () => {
        let all_charts = document.querySelectorAll('.c-chartbox, .c-graph-pptx');
        let c = new PPTX();
        c.start(all_charts);
    });
}




const urlParams = JsHelpers.getUrlParams(window.location.search);
if(urlParams.debug == 'true') {
    window.agregg.debugMode = true;
}

let dataExports = document.querySelectorAll('[data-export]');
if(dataExports != null) {
    dataExports.forEach((dataExport) => {
        dataExport.addEventListener('click', () => {
            let tableElement = document.getElementById(dataExport.getAttribute('data-export'));
            let r = request.post('/export/export/')
                .type('form')
                .send({ 'table': tableElement.outerHTML, 'return_name': 'return_name_true', 'csrfmiddlewaretoken': crsf_tmp })
                .buffer()
                .then(response => { 
                    window.open( JSON.parse(response.text).name );
                 })
        });
    })
}

let tabContainers = document.querySelectorAll('[data-tab-index]');
if(tabContainers != null) {
    tabContainers.forEach((tabContainerElement) => {
        let tabContainer = new PrepareTabs(tabContainerElement);

        let index =  tabContainerElement.getAttribute('data-tab-index');

        let tabs = document.querySelectorAll('[data-tabby="' + index + '"]');
        if(tabs != null) {
            tabs.forEach((tabElement) => {
                let tab = new Tabs(tabElement);
                //tab.init();
            })
        }
    })


    let tabsBar = document.querySelectorAll('.c-chartbox__tabs__bar');

    tabsBar.forEach((element) => {
        element.classList.add('u-invisible');
    })

    setTimeout(function() {
        let tabsContainers = document.querySelectorAll('[data-tabby-content]');
        
        let arrayTabsContainers = [];

        tabsContainers.forEach((element) => {
            if(arrayTabsContainers[element.getAttribute('data-tabby-content')]) {
                arrayTabsContainers[element.getAttribute('data-tabby-content')].push(element.querySelector('.js-chartbox-tab-item'));
            }
            else {
                arrayTabsContainers[element.getAttribute('data-tabby-content')] = [element.querySelector('.js-chartbox-tab-item')];
            }
        });
    
        arrayTabsContainers.forEach((element, index) => {
            element.forEach((elem) => {
                let elemContainsTable = elem.querySelector('.js-table') != undefined;
                if(!elem.classList.contains('u-hide') && !elemContainsTable) {
                    if(document.querySelectorAll('[data-tabby-bar="'+index+'"]')[0]) {
                        document.querySelectorAll('[data-tabby-bar="'+index+'"]')[0].classList.remove('u-hide');
                    }
                }
            })
        })

        let allTabs = document.querySelectorAll('[data-tabby-bar]');
        let setFirst = true;
        allTabs.forEach((elem) => {
            elem.removeAttribute('data-tabby-active');
            if (!elem.classList.contains('u-hide') && setFirst) {
                elem.click();
                setFirst = false;
            } 
        });

        tabsBar.forEach((element) => {
            element.classList.remove('u-invisible');
        })
    }, 2500)
    
}

let chartboxElements = document.querySelectorAll('.js-chartbox');
if(chartboxElements != null) {
    chartboxElements.forEach((chartboxElement) => {
        let chartbox = new ChartBox(chartboxElement);
        chartbox.init();
        window.agregg.chartboxes.push(chartbox);
    })
}

let yearcheckboxes = document.querySelectorAll('.c-filter__year-group [data-year]');
if(yearcheckboxes != null) {
    yearcheckboxes.forEach((yearcheckboxElement) => {
        let yearcheckboxe = new CheckUncheck(yearcheckboxElement);
    })
}