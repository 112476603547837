import * as request from 'superagent';
import * as _ from 'lodash';
import {JsHelpers} from 'helpers/helpers';
import scssVars from 'helpers/scss-var-mapper';

class Table {

    constructor(element, config, chartbox) {
        // PRIVATE PROPERTIES
        this._endpoint = '/bi/tree/';
        this._cacheEndpoint = '/bi/save_html_table/';
        this._kpis = config.kpis;
        this._dashboardId = config.dashboardId;
        this._hideExcel = config.hide_excel;
        this._enable_sorting = config.enable_sorting;
        this._kpitoHide = config.kpi_to_hide;
        this._menuId = config.menuId;
        this._graphId = config.graphId;
        this._order_kpi_code = config.order_kpi_code;
        this._order_by_value = config.order_by_value;
        this._order_reverse = config.order_reverse;
        this._limit = config.limit;
        this._reportId = config.reportId;
        this._drillDown = config.defaultDrillDownId;
        this._containerConfig = config.container;
        this._filterParameters = config.filterParameters;
        this._show_balls = config.show_balls;
        this._show_delta_plus = config.show_delta_plus;
        this._emptyValues = [
            '',
            'None',
        ];
        this._emptyCellContent = '-';
        this.config = config;

        // PUBLIC PROPERTIES
        this.element = element;
        this.chartbox = chartbox;
        this.table = {
            grid: {
                vertical: 0,
                horizontal: 0
            },
            head: [],
            body: [],
            loading: false
        };
    }

    init() {
        this.table.loading = true;
        this.fetchData();
    }

    setExtraConfig(extraConfig) {
    }

    renderRow(row, isHead) {
        let rowElement = document.createElement('tr');
        rowElement.classList.add('c-table__row');
        if (isHead) {
            rowElement.classList.add('c-table__row--title');
        } else {
            rowElement.dataset.order = row.order;
        }
        if (row.clickable) {
            rowElement.classList.add('c-table__row--title');
            rowElement.classList.add('is-clickable');
        }
        if (row.togglable) {
            rowElement.classList.add('is-togglable');
            rowElement.classList.add('is-hidden');
            rowElement.classList.add('c-table__row--indent-' + this.rowIndentLevel);
            rowElement.dataset.parentRow = row.parentRow;
        }
        if (row.class) {
            rowElement.classList.add(row.class);
        }
        let tot = []
        let max_num;

        if (this.config && this.config.show_max_bold) {
            _.each(row.cells, (cell, col_number) => {
                let val = parseFloat(cell.content.replace(/\./g, '').replace(/,/g, '.'));
                if (!isNaN(val)) {
                    tot.push(val);
                }
            });
            max_num = Math.max.apply(null, tot);
        }
        ;

        _.each(row.cells, (cell, col_number) => {
            var extra_class = '';
            if (this.config && this.config.show_max_bold) {
                if (parseFloat(cell.content.replace(/\./g, '').replace(/,/g, '.')) == max_num) {
                    extra_class = 'c-table__cell--max';
                }
            }
            rowElement.appendChild(this.renderCell(cell, isHead, col_number, extra_class));
        });

        return rowElement;
    }

    renderCell(cell, isHead, col_number, extra_class) {
        let el = isHead ? 'th' : 'td';
        let cellElement = document.createElement(el);
        cellElement.classList.add('c-table__cell');
        if (!cell.type) {
            cell.type = 'integer';
        }

        if (this._show_balls == 'true' && cell.type != 'text') {
            cellElement.classList.add('c-table__cell--balls');
        } else {
            cellElement.classList.add('c-table__cell--' + cell.type);
        }

        if (extra_class) {
            cellElement.classList.add(extra_class);
        }

        if (!_.isUndefined(cell.analysisResult) && !_.isNull(cell.analysisResult)) {
            cellElement.classList.add('c-table__cell--' + cell.analysisResult);
            //console.log(this.config);
            if (this.config && !this.config.hide_cell_plus) {
                if (!cell.content.startsWith('-')) {
                    cell.content = '+' + cell.content;
                }
            }
        }

        if (parseInt(this._show_delta_plus) == col_number && parseInt(this._show_delta_plus) > 0 && !isHead) {
            if (!cell.content.startsWith('-')) {
                cell.content = '+' + cell.content;
            }
        }

        let cellDataElement = document.createElement('div');
        cellDataElement.classList.add('c-table__data');
        if (this._show_balls == 'true') {
            if (!_.isUndefined(cell.analysisResult) && !_.isNull(cell.analysisResult)) {
                cellDataElement.classList.add('c-table__cell__balls');
                cellDataElement.classList.add('c-table__cell__balls--' + cell.analysisResult);
                cellDataElement.title = cell.content;
                cellElement.classList.add('c-table__cell--balls');
            }
        }

        if (cell.class && cell.class.replace(/ /g, '') != '') {
            cellDataElement.classList.add(cell.class.replace(/ /g, ''));
        }

        let cellContentElement = document.createElement('span');
        cellContentElement.textContent = cell.content;

        cellElement.rowSpan = cell.rowSpan;
        cellElement.colSpan = cell.colSpan;

        if (cell.kpi) {
            cellElement.dataset.kpi = cell.kpi;
        }

        cellDataElement.appendChild(cellContentElement)
        cellElement.appendChild(cellDataElement);
        return cellElement;
    }

    render() {
        if (this.table.head.length) {
            this.head = document.createElement('thead');
            this.head.classList.add('c-table__head');
            _.each(this.table.head, headRow => {
                this.head.appendChild(this.renderRow(headRow, true));
            });
            this.element.appendChild(this.head);
        }

        if (this.config.min_width) {
            this.element.setAttribute("style", "min-width:" + this.config.min_width + 'px');
        }

        this.body = document.createElement('tbody');
        this.body.classList.add('c-table__body');
        let currentClickableRow = 0;
        this.rowIndentLevel = 0;
        this.map = new Map();
        _.each(this.table.body, bodyRow => {
            if (!bodyRow.togglable) {
                this.rowIndentLevel = 0;
            } else if (bodyRow.parentRow < currentClickableRow && this.rowIndentLevel > 0) {
                this.rowIndentLevel--;
            }

            if (bodyRow.parentRow > 0) {
                this.rowIndentLevel = this.map[bodyRow.parentRow];
                //console.log("++",bodyRow.order);
            }


            // if(this.beforeParentRow > bodyRow.parentRow  && this.rowIndentLevel > 2 ) {
            //     this.rowIndentLevel--;
            //     console.log("--",bodyRow.order);
            // }


            this.body.appendChild(this.renderRow(bodyRow, false));

            if (bodyRow.clickable) {
                currentClickableRow = bodyRow.order;
                this.rowIndentLevel++;
            }

            this.map[bodyRow.order] = this.rowIndentLevel;
        });

        //console.log(this.map);
        this.element.appendChild(this.body);

        if (this.table.body.length == 0) {
            this.element.innerHTML = 'Per questo mese nessun dato è disponibile';
        }

        this.bindEvents();
        this.table.loading = false;

        this.renderCallback();

        let _this1 = this;

        // bounding
        setTimeout(
            function () {
                _this1.flyingHeader(_this1.element, _this1.head);
            }, 500
        )

        //this.fixTable(this.chartbox.element.querySelector('.c-chartbox__body'));
        //console.log(this.chartbox.element.querySelector('.c-chartbox__body'));
    }

    renderFromCache(html) {
        this.element.innerHTML = html;
        this.bindEvents();
        this.table.loading = false;
        this.renderCallback();

        let _this1 = this;
        setTimeout(
            function () {
                _this1.flyingHeader(_this1.element, _this1.element.querySelector('thead'));
            }, 500
        )
    }

    order() {
        _.orderBy(this.table.head, ['order', 'asc']);
        _.each(this.table.head, row => {
            row.cells = _.orderBy(row.cells, ['order', 'asc']);
        });

        _.orderBy(this.table.body, ['order', 'asc']);
        _.each(this.table.body, row => {
            row.cells = _.orderBy(row.cells, ['order', 'asc']);
        });
    }

    enableSorting(el) {
        if (this._enable_sorting || this._enable_sorting == 'true' || this._enable_sorting == 'True') {
            let clickableHeaders;
            if (el) {
                clickableHeaders = el.querySelectorAll('.c-table__row--title');
            } else {
                clickableHeaders = this.element.querySelectorAll('.c-table__row--title');
            }
            _.each(clickableHeaders, row => {
                _.each(row.cells, cell => {
                    cell.addEventListener('click', () => {
                        this.sortTable(cell, cell.cellIndex);
                    });
                });
            });
        }
    }

    toggleRow(rowElement) {
        rowElement.classList.toggle('is-open');
        let nextRowElement = rowElement.nextElementSibling;
        let clickedRowOrder = rowElement.dataset.order;
        while (nextRowElement && nextRowElement.classList.contains('is-togglable')) {
            if (nextRowElement.dataset.parentRow == clickedRowOrder) {
                nextRowElement.classList.toggle('is-hidden');
            }

            if (nextRowElement.classList.contains('is-clickable') && nextRowElement.classList.contains('is-open')) {
                this.toggleRow(nextRowElement);
            }

            nextRowElement = nextRowElement.nextElementSibling;

        }
    }

    bindEvents() {
        let clickableRows = this.element.querySelectorAll('.c-table__row.is-clickable');
        _.each(clickableRows, rowElement => {
            rowElement.addEventListener('click', () => {
                if (this.clonedTable) {
                    let cloneRow = this.clonedTable.querySelector('[data-order="' + rowElement.dataset.order + '"]');
                    this.toggleRow(cloneRow);
                }
                this.toggleRow(rowElement);
            });
        });
    }

    updateGrid(row) {
        this.table.grid.vertical = this.table.head.length + this.table.body.length;
        this.table.grid.horizontal = Math.max(this.table.grid.horizontal, row.cells.length);
    }

    updateHead(keys, descriptions, periods, multi_periods) {
        _.each(keys, (key, index) => {
            _.each(this.table.head, row => {
                let headCell = _.find(row.cells, {'order': index + 1 + row.offset});
                if (_.isUndefined(headCell) && !multi_periods) {
                    row.cells.push({
                        type: 'text',
                        content: descriptions[index].trim().replace(/(&nbsp;)/g, ' - '),
                        value: null,
                        kpi: null,
                        analysisResult: null,
                        order: index + 1 + row.offset,
                        colSpan: 1,
                        rowSpan: 1,
                    });
                } else if (!_.isUndefined(headCell)) {
                    headCell.content = descriptions[index];
                }
                this.updateGrid(row);
            });
        });
    }


    sortTable(cell, col_index) {
        let table, rows, switching, i, x, y, shouldSwitch;
        table = this.element;
        switching = true;

        let reverse = cell.classList.contains('order-reverse');

        while (switching) {
            switching = false;
            rows = table.rows;
            for (i = 1; i < (rows.length - 1); i++) {
                shouldSwitch = false;
                x = rows[i].getElementsByTagName("td")[col_index];
                y = rows[i + 1].getElementsByTagName("td")[col_index];
                if (reverse) {
                    if (parseFloat(x.innerText.toLowerCase().replace(/\./g, '')) < parseFloat(y.innerText.toLowerCase().replace(/\./g, ''))) {
                        shouldSwitch = true;
                        break;
                    }
                } else {
                    if (parseFloat(x.innerText.toLowerCase().replace(/\./g, '')) > parseFloat(y.innerText.toLowerCase().replace(/\./g, ''))) {
                        shouldSwitch = true;
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
            }
        }
        cell.classList.toggle('order-reverse');
    }

    generateHead(headRowsData) {
        _.each(headRowsData, rowData => {
            let row = {
                id: rowData.header_row_number,
                order: rowData.header_row_number,
                clickable: false,
                togglable: false,
                class: rowData.cell_class,
                kpis: [],
                cells: []
            };
            _.each(rowData.cells, cellData => {
                let colIndex = 0;
                row.cells.push({
                    type: 'text',
                    content: cellData.cell_text.trim().replace(/(&nbsp;)/g, ' - '),
                    periodId: cellData.period__period_id,
                    value: null,
                    kpi: cellData.kpi,
                    analysisResult: null,
                    order: cellData.data_cell_number,
                    colSpan: cellData.column_span,
                    rowSpan: cellData.row_span,
                });
                colIndex += 1;
            });
            row.offset = row.cells.length;
            this.table.head.push(row);
            this.updateGrid(row);
        });
    }

    generateBody(bodyRowsData, periods, multi_periods) {
        if (periods && periods.length > 1 && multi_periods) {
            _.each(bodyRowsData, rowData => {
                let offset = 0;

                let row = {
                    id: rowData.row_id,
                    order: rowData.data_row_number,
                    clickable: rowData.click_row,
                    togglable: rowData.toggle_row,
                    parentRow: rowData.parent_click_row || 0,
                    class: rowData.cell_class,
                    kpis: rowData.kpi_code ? rowData.kpi_code.split(';') : [],
                    cells: []
                };

                let cell = {
                    type: 'text',
                    content: rowData.cells[0].cell_text,
                    periodId: null,
                    class: '',
                    value: null,
                    kpi: rowData.kpi_code,
                    analysisResult: null,
                    order: 0,
                    colSpan: 1,
                    rowSpan: 1,
                };
                row.cells.push(cell);
                if (cell.type != 'empty') {
                    offset++;
                }

                _.each(periods, period => {
                    let cell = {
                        type: period.cell_value ? 'empty' : 'text',
                        content: '',
                        periodId: period.period_id,
                        class: '',
                        value: null,
                        kpi: rowData.kpi_code,
                        analysisResult: null,
                        order: parseInt(period.index) + 1,
                        colSpan: 1,
                        rowSpan: 1,
                    };
                    row.cells.push(cell);
                    if (cell.type != 'empty') {
                        offset++;
                    }
                });
                row.offset = offset;
                //this.generateEmptyCells(row);
                this.table.body.push(row);
                this.updateGrid(row);
            });
        } else {
            _.each(bodyRowsData, rowData => {
                let row = {
                    id: rowData.row_id,
                    order: rowData.data_row_number,
                    clickable: rowData.click_row,
                    togglable: rowData.toggle_row,
                    parentRow: rowData.parent_click_row || 0,
                    class: rowData.cell_class,
                    kpis: rowData.kpi_code ? rowData.kpi_code.split(';') : [],
                    cells: []
                };
                let offset = 0;
                _.each(rowData.cells, cellData => {
                    let cell = {
                        type: cellData.cell_value ? 'empty' : 'text',
                        content: cellData.cell_text.trim().replace(/(&nbsp;)/g, ' - '),
                        class: cellData.cell_class,
                        periodId: cellData.period__period_id,
                        value: null,
                        kpi: cellData.cell_value,
                        analysisResult: null,
                        order: cellData.data_cell_number,
                        colSpan: cellData.column_span,
                        rowSpan: cellData.row_span,
                    };
                    row.cells.push(cell);
                    if (cell.type != 'empty') {
                        offset++;
                    }
                });
                row.offset = offset;
                this.generateEmptyCells(row);
                this.table.body.push(row);
                this.updateGrid(row);
            });
        }
    }

    generateEmptyCells(row) {
        // Se la cella è vuota, sostituisce con valore di this.emptycellcontent

        _.each(row.kpis, (kpi, index) => {
            let cell = _.find(row.cells, {'kpi': kpi});
            if (_.isUndefined(cell)) {
                row.cells.push({
                    type: 'decimal', // per allineare numeri
                    content: this._emptyCellContent,
                    value: null,
                    kpi: kpi,
                    analysisResult: null,
                    order: index + 1 + row.offset,
                    colSpan: 1,
                    rowSpan: 1
                });
            }
        });
    }

    generateRow(rowData) {
        // GENERIC ROW DEFINITION
        let kpis = _.isUndefined(rowData.kpis) ? [] : rowData.kpis.split(';');
        return {
            id: rowData.id,
            order: rowData.order,
            clickable: rowData.clickable || false,
            togglable: rowData.togglable || false,
            parentRow: rowData.parentRow || 0,
            class: rowData.class,
            kpis: kpis,
            cells: []
        };
    }

    generateCell(cellData, row) {
        // GENERIC CELL DEFINITION
        let offset = _.isUndefined(row) ? 0 : row.offset;
        let cellContent = cellData.content;
        console.log(cellContent);
        if (cellContent === '' || cellContent === '0.00' || cellContent === '0.00%' || cellContent === '0') {
        // Mette il trattino nelle celle dei tabreport pari a 0 o vuote
            cellContent = this._emptyCellContent;
        }
        cellContent.trim().replace(/(&nbsp;)/g, ' - ')
        return {
            type: cellData.type || 'text',
            content: cellContent,
            periodId: cellData.period__period_id,
            value: cellData.value,
            kpi: cellData.kpi,
            class: cellData.class,
            analysisResult: cellData.analysisResult,
            order: cellData.order + offset,
            col_number: cellData.col_number,
            colSpan: cellData.col_span || 1,
            rowSpan: cellData.row_span || 1,
        };
    }

    addRow(rowData) {
        let row = _.find(this.table.body, {id: rowData.row_id});
        if (_.isUndefined(row)) {
            row = this.generateRow(rowData);
            this.table.body.push(row);
        }
        _.each(rowData.values, cellData => {
            this.addCell(cellData, row.id);
        });
        this.updateGrid(row);

    }

    addCell(cellData, rowId) {
        let row = _.find(this.table.body, {id: rowId});
        if (!_.isUndefined(row)) {
            let cell = _.find(row.cells, {'kpi': cellData.kpi_code, 'periodId': cellData.period__period_id});
            if (_.isUndefined(cell)) {
                cell = _.find(row.cells, {'kpi': cellData.kpi_code}); // provo senza periodo...
            }

            if (_.isUndefined(cell)) {

                row.cells.push(this.generateCell(cellData, row));
            } else {
                let index = _.indexOf(row.cells, cell);
                //console.log(index, cellData.kpi_value_formatted, cellData.period__period_id);
                cellData.col_number = index;
                row.cells[index] = this.generateCell(cellData, row);
            }
        }
    }

    getCustomParams() {
        return {};
    }

    removeEmptyRow(rowsData) {

        let editedRowsData = []

        _.each(rowsData, rowData => {
            let all_none = true;
            _.each(rowData.values, value => {
                if (value.size != 'None' && value.size != 0 && value.kpi_code != this._kpitoHide) {
                    all_none = false;
                }
            });
            if (!all_none) {
                editedRowsData.push(rowData);
            }
        });

        return editedRowsData;

    }

    fetchData() {
        // PREPARE REQUEST BASED ON KPI CONFIG
        let kpisParam = [];
        _.each(_.orderBy(this._kpis, 'order'), kpi => {
            kpisParam.push(kpi.key);
        });
        let params = {
            kpi: kpisParam.join(','),
            menu_id: this._menuId,
            order_kpi_code: this._order_kpi_code,
            order_by_value: this._order_by_value,
            order_reverse: this._order_reverse,
            limit: this._limit,
            report_id: this._reportId,
            from_who: 1,
            debug: window.agregg.debugMode
        };

        if (this._period != undefined) {
            params['per'] = this._period;
        }

        if (this._drillDown != undefined) {
            params['drill_down_id'] = this._drillDown;
        }
        params = _.merge(params, this.getCustomParams());

        let initRequest = request.get(this._endpoint)
            .set('Accept', 'application/json')
            .query(params).query(this._filterParameters).then(response => {
                return response.body;
            });

        // HANDLE REQUEST RESPONSE
        initRequest.then(response => {
            //console.log(response.html_table);


            if (response.cache_key) {
                this.cache_key = response.cache_key;
            }

            if (response.html_table) {
                this.renderFromCache(response.html_table);
                this.enableSorting();
            } else if (response.data) {
                let order = 1;
                let rowsData = []
                _.each(response.data, colData => {
                    _.each(colData.children, kpiData => {
                        _.each(kpiData.values, kpiDataValue => {
                            if (colData.kpi_code != this._kpitoHide) {
                                let f = rowsData.find(x => x.name == kpiDataValue.name);

                                kpiDataValue['kpi_code'] = colData.kpi_code;
                                if (f) {
                                    f.values.push(kpiDataValue);
                                } else {
                                    rowsData.push({'order': order, 'id': kpiDataValue.name, 'name': kpiDataValue.name, 'values': [kpiDataValue]});
                                    order += 1;
                                }
                            }

                        });
                    });

                });
                let titles = '';
                try {
                    titles = response.data[0].children[1].type_name;
                } catch (e) {
                    try {
                        titles = response.data[0].children[0].type_name;
                    } catch (e) {
                        titles = '';
                    }
                    ;
                }
                ;
                if (!titles) {
                    titles = '';
                }
                let title_list = titles.split(' - ');
                let header_cells = [];
                let use_split = false;

                if (title_list.length > 1) {
                    use_split = true;
                } else {
                    title_list = [' '];
                }

                let order_header = 1;
                _.each(title_list, txt => {
                    header_cells.push({'cell_text': txt, 'data_cell_number': order_header, 'column_span': 1, 'row_span': 1});
                    order_header += 1;
                })
                _.each(this._kpis, kpi => {
                    if (kpi.key != this._kpitoHide) {
                        header_cells.push({'cell_text': kpi.kpi_title, 'kpi': kpi.key, 'data_cell_number': order_header, 'column_span': 1, 'row_span': 1});
                        order_header += 1;
                    }
                })
                let header = [{'header_row_number': 1, 'cells': header_cells}];
                this.generateHead(header);

                rowsData = this.removeEmptyRow(rowsData);

                _.each(rowsData, rowData => {
                    this.addRow(rowData, use_split);
                });

                this.render();
                //this.order();
                this.enableSorting();
            } else {

                this.generateHead(response.headers);
                this.generateBody(response.rows, response.periods, response.multi_periods);

                // PREPARE REQUESTS BASED ON QUERY PARAMS
                let requests = [];

                _.each(response.query_array, queryParam => {
                    let rowParams = params;
                    rowParams.row_id = queryParam;
                    let rowRequest = request.get(this._endpoint)
                        .set('Accept', 'application/json')
                        .query(rowParams).query(this._filterParameters).then(response => {
                            return response.body;
                        });

                    rowRequest.then(response => {
                        _.each(response, rowData => {
                            this.addRow(rowData);
                        })
                    });
                    requests.push(rowRequest);
                });
                Promise.all(requests).then(responses => {
                    if (this._tableType && this._tableType == 'tabular') {
                    } else {
                        this.order();
                    }
                    this.render();
                    this.enableSorting();
                });

            }

        });
    }

    renderCallback() {
        if (this.chartbox) {
            this.chartbox.render();
        }

        if (this.cache_key) {
            this.updateCache();
        }

    }

    isInViewport(el) {
        let top = el.offsetTop;
        let height = el.offsetHeight;
        //console.log(el.offsetParent);
        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
        }

        return (
            top < (window.pageYOffset + window.innerHeight) &&
            (top + height - 50) > window.pageYOffset
        );
    }


    flyingHeader(table, header) {
        if (header) {
            let headerHeight = header.offsetHeight;
            let flyingHeader = document.createElement('div');
            flyingHeader.style.height = headerHeight + 'px';
            flyingHeader.classList.add('c-chartbox__sticky');
            this.clonedTable = table.cloneNode(['deep']);
            this.clonedTable.setAttribute('data-sticky-id', this.clonedTable.id);
            this.clonedTable.removeAttribute('id');
            this.clonedTable.classList.add('c-table--cloned');
            flyingHeader.append(this.clonedTable);
            table.parentNode.insertBefore(flyingHeader, table);

            this.enableSorting(this.clonedTable);

            let lastScrollLeft = 0;

            window.addEventListener('resize', JsHelpers.debounce((event) => {
                let headerHeight = header.offsetHeight;
                flyingHeader.style.height = headerHeight + 'px';
            }, 200), false);

            table.parentNode.addEventListener('scroll', (event) => {
                //console.log(event.target.scrollLeft,table.parentNode.scrollLeft);
                if (event.target.scrollLeft == lastScrollLeft) {
                    flyingHeader.style.opacity = 0;
                }
            }, false);

            table.parentNode.addEventListener('scroll', JsHelpers.debounce((event) => {
                let top = event.target.scrollTop;

                if (table.parentNode.classList.contains('ps--active-x') && window.matchMedia(scssVars.breakpoints['phone']).matches && top == 0) {
                    flyingHeader.style.top = (top + 16) + "px";
                } else {
                    flyingHeader.style.top = top + "px";
                }

                flyingHeader.style.opacity = 1;

                lastScrollLeft = table.parentNode.scrollLeft;
            }, 200), false);
        }
    }

    updateCache() {
        //console.log(this.element);
        let cacheUpdate = request.post(this._cacheEndpoint)
            .send(
                {
                    cache_key: this.cache_key,
                    html: {'html_table': this.element.outerHTML}
                }
            )
            .set('Accept', 'application/json')
            .set("X-CSRFToken", this.getCookie('csrftoken'))
            .then(response => {
                //console.log(response);
                return response.body;
            });
    }

    getCookie(c_name) {
        if (document.cookie.length > 0) {
            let c_start = document.cookie.indexOf(c_name + "=");
            if (c_start != -1) {
                c_start = c_start + c_name.length + 1;
                let c_end = document.cookie.indexOf(";", c_start);
                if (c_end == -1) {
                    c_end = document.cookie.length;
                }
                return unescape(document.cookie.substring(c_start, c_end));
            }
        }
        return "";
    }

}

export {Table}