import * as request from 'superagent';
import * as _ from 'lodash';
import {Table} from 'modules/table';

class TabularTable extends Table {

    constructor(element, config, chartbox) {
        super(element, config, chartbox);
        this._tableType = 'tabular';
        this.config = config;
        this.hide_col = false;

    }

    renderCallback() {
        if (this.chartbox) {
            this.chartbox.render();
            if (this.hide_col) {
                this.config.cols_to_hide.forEach(col_to_hide => {
                    this.hide_column(col_to_hide);
                    console.log(col_to_hide);
                });
            }
        }

        if (this.cache_key) {
            this.updateCache();
        }

    }

    hide_column(col_no) {
        var rows = this.element.getElementsByTagName('tr');
        for (var row = 0; row < rows.length; row++) {
            var cels = rows[row].getElementsByTagName('td')
            if (!cels || cels.length == 0) {
                var cels = rows[row].getElementsByTagName('th')
            }
            cels[col_no].style.display = "none";
        }
    }

    hideCol(col_no) {
        var col = document.getElementById("txtCol").value;
        if (isNaN(col) || col == "") {
            alert("Invalid Column");
            return;
        }
        col = parseInt(col, 10);
        col = col - 1;
        var tbl = document.getElementById("tblMain");
        if (tbl != null) {
            if (col < 0 || col >= tbl.rows.length - 1) {
                alert("Invalid Column");
                return;
            }
            for (var i = 0; i < tbl.rows.length; i++) {
                for (var j = 0; j < tbl.rows[i].cells.length; j++) {
                    tbl.rows[i].cells[j].style.display = "";
                    if (j == col)
                        tbl.rows[i].cells[j].style.display = "none";
                }
            }
        }
    }

    generateBody(bodyRowsData, periods, multi_periods) {
        super.generateBody(bodyRowsData, periods, multi_periods);
        _.each(bodyRowsData, rowData => {
            let row = _.find(this.table.body, {'id': rowData.row_id});
            if (!_.isUndefined(row)) {
                let kpiDesc = rowData.kpi_description.split(';');
                this.updateHead(row.kpis, kpiDesc, periods, multi_periods);
            }
        });
    }

    generateRow(rowData) {
        return {
            id: rowData.row_id,
            order: rowData.data_row_number,
            clickable: false,
            togglable: false,
            parentRow: rowData.parent_click_row || 0,
            class: rowData.cell_class,
            kpis: rowData.kpi_code.split(';'),
            cells: []
        };
    }

    addRow(rowData) {
        let all_none = true;
        let clickable = true;

        _.each(rowData.values, value => {
            if (value.kpi_value != 'None' && parseFloat(value.kpi_value) != 0 && parseFloat(value.kpi_value) != -100 && value.kpi_code != this._kpitoHide) {
                all_none = false;
            }
        });

        _.each(rowData.values, value => {
            if (parseInt(value.period__period_id) >= parseInt(this.config.period_to_hide_from) && this.config.rows_to_hide.indexOf(rowData.data_row_number) > -1) {
                all_none = true;
                this.config.show_nulls == 'false';
                return;
            }
        });

        _.each(rowData.values, value => {
            if (parseInt(value.period__period_id) >= parseInt(this.config.period_to_hide_from)) {
                this.hide_col = true;
                return;
            }
        });

        _.each(rowData.values, value => {
            if (this.config.click_row_to_disable && parseInt(value.period__period_id) >= parseInt(this.config.period_to_hide_from) && this.config.click_row_to_disable.indexOf(rowData.data_row_number) > -1) {
                clickable = false;
                return;
            }
        });


        if (!all_none || this.config.show_nulls == 'true') {
            let row = _.find(this.table.body, {id: rowData.row_id});
            if (_.isUndefined(row)) {
                row = this.generateRow(rowData);
                this.table.body.push(row);
            }
            _.each(rowData.values, cellData => {
                this.addCell(cellData, row.id);
            });

            if (clickable === false) {
                row.clickable = false
            }

            this.updateGrid(row);
        } else {
            let row = _.find(this.table.body, {id: rowData.row_id});

            if (clickable === false) {
                row.clickable = false
            }

            row.class = 'u-hide';
            //console.log('remove row done', rowData.row_id, row );
        }
    }

    generateCell(cellData, row) {
        if (!cellData.kpi_value || cellData.kpi_value == 0) {
            cellData.kpi_value_formatted = ' - ';
        }
        let cell = {
            type: cellData.kpi_measure_type,
            periodId: cellData.period__period_id,
            content: cellData.kpi_value_formatted.trim().replace(/(&nbsp;)/g, ' - '),
            value: parseFloat(cellData.kpi_value),
            kpi: cellData.kpi_code,
            analysisResult: cellData.kpi_analysis_result,
            order: _.indexOf(row.kpis, cellData.kpi_code) + 1 + row.offset,
            colSpan: 1,
            col_number: cellData.col_number,
            rowSpan: 1
        };
        if (this._emptyValues.includes(cell.content)) {
            cell.type = 'decimal';
            cell.content = this._emptyCellContent;
        }
        return cell;
    }

    getCustomParams() {
        return {
            custom_view: 'get_json_for_tabular_report'
        };
    }
}

export {TabularTable}